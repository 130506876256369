import React, { useEffect, useState } from "react";
import SequenceDropdown from "./SequenceDropdown";
import SSCButton from "./SSCButton";
import { Person } from "../types/Person";
import { SequenceOption } from "../types/SequenceOption";
import EmailEditor from "./EmailEditor";
import ReactModal from "react-modal";

interface PersonalizerProps {
  load: () => void;
  lead?: Person;
  sequenceOptions: SequenceOption[];
  selectedSequence?: SequenceOption;
  setSelectedSequence: (option: SequenceOption) => void;
  selectedEmailIndex: number;
  setSelectedEmailIndex: (index: number) => void;
  handleEditEmail: (update: object) => void;
  onSendEmail: (paragraphText: string, linkText: string) => void;
  isSending?: boolean;
  handleSkipLead: () => void;
  handleDeleteLead: () => void;
}

const EmailPersonalizer = ({
  load,
  lead,
  sequenceOptions,
  selectedSequence,
  setSelectedSequence,
  selectedEmailIndex,
  setSelectedEmailIndex,
  handleEditEmail,
  onSendEmail,
  isSending = false,
  handleSkipLead,
  handleDeleteLead
}: PersonalizerProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [paragraphText, setParagraphText] = useState<string>("");
  const [linkText, setLinkText] = useState<string>("");

  useEffect(() => {
    load();
  }, []);

  const showModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleTabClick = (index: number) => {
    setSelectedEmailIndex(index);
  };

  const onSkipLead = () => {
    handleSkipLead();
    closeDeleteModal();
  }

  const onDeleteLead = () => {
    handleDeleteLead();
    closeDeleteModal();
  }

  return (
    <>
      <div className="personalize_topSection">
        <div className="personalize_topSection_name">
          <h5 className="noMargin opacity50">Lead name</h5>
          <h1 className="noMargin">{lead?.name}</h1>
        </div>
        <div className="personalize_buttons">
          {lead?.linkedIn && (
            <>
              <a
                className="personalize_links mainButton mainButton_smaller"
                href={
                  lead?.linkedIn.startsWith("https")
                    ? lead.linkedIn
                    : `https://${lead.linkedIn}`
                }
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </>
          )}

          {lead?.website && (
            <>
              <a
                className="personalize_links mainButton mainButton_smaller"
                href={
                  lead.website.startsWith("http")
                    ? lead.website
                    : `http://${lead.website}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Website
              </a>
            </>
          )}
        </div>
      </div>

      <div className="personalize_section2">
        <div className="personalize_leftSide">
          <iframe src={lead?.website} title="Lead Website" />
        </div>

        <div className="personalize_rightSide">
          <div>
            <SequenceDropdown
              options={sequenceOptions}
              selectedOption={selectedSequence}
              onOptionSelected={setSelectedSequence}
            />
          </div>

          <div className="">
            <div className="robsTest_emailTabs">
              {selectedSequence?.emails?.map((_, index) => (
                <div
                  key={index}
                  className={`emailTab ${
                    selectedEmailIndex === index ? "emailTab_active" : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  Email {index + 1}
                </div>
              ))}
            </div>

            <div className="tab-content">
              <div>
                <div className="subjectLineDiv">
                  <div className="labelDiv">
                    <label className="fieldLabel">Subject Line</label>
                  </div>
                  <input
                    value={
                      selectedSequence?.emails?.at(selectedEmailIndex)?.subject
                    }
                    onChange={(e) =>
                      handleEditEmail({ subject: e?.target?.value })
                    }
                    className="subjectLine"
                  />
                </div>
              </div>

              {/* Where the messages are sent */}
              <div className="messageDiv">
                <div className="labelDiv">
                  <label className="fieldLabel">Message</label>
                </div>
                <div className="textAreaDiv">
                  <EmailEditor
                    emailBody={
                      selectedSequence?.emails?.at(selectedEmailIndex)
                        ?.body as string
                    }
                    lead={lead as Person}
                    paragraphText={paragraphText}
                    setParagraphText={setParagraphText}
                    linkText={linkText}
                    setLinkText={setLinkText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomButtonDiv">
        <button className="notAFit" onClick={() => showModal()}>
          This person isn't a fit
        </button>
        <SSCButton
          text="Send Email"
          onClick={() => onSendEmail(paragraphText, linkText)}
          className="mainButton"
          isLoading={isSending}
        />
      </div>

      <ReactModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            border: "none",
            display: "flex",
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          },
          content: {
            width: "480px",
            height: "auto",
            margin: "auto",
            inset: "auto",
            paddingRight: 32,
            paddingLeft: 32,
          },
        }}
        ariaHideApp={false}
      >
        <h2 className="marginBottom8px">
          If people aren't a fit, you should delete them.{" "}
        </h2>
        <p className="marginBottom24px">
          That way you aren't wasting your time sending emails to people. How
          would you like to proceed?{" "}
        </p>

        <div className="modal__buttonContainer--rightLeft">
          <SSCButton
            text="Cancel"
            onClick={closeDeleteModal}
            className="mainButton opacity50 marginRight12px"
          />

          <SSCButton
            text="Skip Person"
            onClick={onSkipLead}
            className="mainButton opacity50"
          />

          <SSCButton
            text="Delete Person"
            onClick={onDeleteLead}
            className="mainButton"
          />
        </div>
      </ReactModal>
    </>
  );
};

export default EmailPersonalizer;